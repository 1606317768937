import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/navigation';
import {
  useLogoutMutation, api as authApi,
  useLazyUpdateMeQuery,
  useLazyGetMeQuery,
} from 'lib/features/authOAuth2/api';
import { useAppSelector, useAppDispatch } from 'lib/hooks';
import {
  providerSelector, userSelector, loadingAuthSelector, accessTokenSelector, isProviderSelector, resetState,
} from 'lib/features/authOAuth2';
import { Providers, User } from 'lib/features/authOAuth2/types';
import getConfig from 'config';

export interface UseAuthOAuth2 {
  provider?: Providers | null;
  user?: User | null;
  loading: boolean;
  isUserConnected: boolean;
  isAuthChecked: boolean;
  logout: () => Promise<void>;
  login: (provider: Providers) => Promise<void>;
  isProvider: boolean;
  getIsNewProvider: (provider?: unknown) => boolean;
  updateCurrentUser: ReturnType<typeof useLazyUpdateMeQuery>[0];
  getCurrentUser: ReturnType<typeof useLazyGetMeQuery>[0];
  checkCanLogin: (provider?: Providers) => Promise<boolean>;
}

export const useAuthOAuth2 = (): UseAuthOAuth2 => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const accessToken = useAppSelector(accessTokenSelector);
  const isProvider = useAppSelector(isProviderSelector);
  const user = useAppSelector(userSelector);
  const loadingAuth = useAppSelector(loadingAuthSelector);
  const provider = useAppSelector(providerSelector);
  const updateProvidersState = useMemo(() => authApi.endpoints.updateProviders.select(null), []);
  const updateMeState = useMemo(() => authApi.endpoints.updateMe.select(null), []);
  const updateProvidersResponse = useAppSelector(updateProvidersState);
  const updateMeStateResponse = useAppSelector(updateMeState);
  const [logoutMutation, logoutReponse] = useLogoutMutation();
  const [updateCurrentUser] = useLazyUpdateMeQuery();
  const [getCurrentUser] = useLazyGetMeQuery();

  const redirect = useCallback((provider: Providers) => {
    if (!provider) return;
    router.push(`${getConfig().NEXT_PUBLIC_AUTH_API}/api/auth/ext/${provider}`);
  }, [router]);

  const logout = useCallback(async () => {
    await logoutMutation().catch(() => {});
    dispatch(resetState());
  }, [logoutMutation, dispatch]);

  const login = useCallback(async (provider: Providers) => {
    return redirect(provider);
  }, [redirect]);

  const isUserConnected = !!provider && !!user;

  const isAuthChecked = !accessToken || updateMeStateResponse.status !== 'uninitialized';

  const loading = logoutReponse?.isLoading
   || loadingAuth
   || updateProvidersResponse?.isLoading
   || updateMeStateResponse?.isLoading
    || false;

  const getIsNewProvider = useCallback((newProvider?: unknown) => {
    if (!newProvider) return false;
    return Object.values(Providers).includes(newProvider as Providers);
  }, []);

  const checkCanLogin = useCallback(async (newProvider?: Providers) => {
    if (getIsNewProvider(newProvider)) return true;
    throw new Error('Bad provider');
  }, [getIsNewProvider]);

  return {
    user,
    provider,
    isUserConnected,
    loading,
    isAuthChecked,
    login,
    logout,
    isProvider,
    getIsNewProvider,
    updateCurrentUser,
    getCurrentUser,
    checkCanLogin,
  };
};