import { useCallback } from 'react';
import { useAppSelector } from 'lib/hooks';
import { selectedWalletTypeSelector } from 'lib/features/wallet';
import { getWeb3InstanceByWalletType } from 'lib/features/wallet/utils/web3Provider';
import { SelectedWalletType } from '../types';

const useWeb3Provider = () => {
  const selectedWalletType = useAppSelector(selectedWalletTypeSelector);

  const getWeb3Instance = useCallback(async (walletType?: SelectedWalletType | null) => {
    return getWeb3InstanceByWalletType(walletType ?? selectedWalletType);
  }, [selectedWalletType]);

  return { getWeb3Instance };
};

export default useWeb3Provider;